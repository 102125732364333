.default-error-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}

.default-error-p {
    margin: 0;
    color: var(--White, #FFF);
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.optinal-error-message {
    margin: 0;
    align-self: stretch;
    color: var(--Gray, #919191);
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.default-error-button {
    display: flex;
    height: 3.375rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 1rem;
    border: 2px solid var(--White, #FFF);
    color: #FFFFFF;
    background-color: #1B1B1C;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.error-button-p {
    color: var(--White, #FFF);
    font-family: "Work Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}