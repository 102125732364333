.checkbox-input {
    /* Remove o estilo padrão do checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Define o tamanho do checkbox */
    width: 22px;
    height: 22px;

    /* Define a borda cinza quando desmarcado */
    /*
    border: 2px solid #ccc;
    border-radius: 4px;
    */
    /* Borda arredondada */

    /* Remove o fundo */

    /* Posiciona o conteúdo do checkbox (o check) */
    display: inline-flex;
    align-items: center;
    justify-content: center;

    /* Aumenta o tamanho do checkbox */
    transform: scale(1.1);
    
    background-image: url("../../../Assets/Icons/check_box_blank.png");
    /* Aumenta o tamanho em 20% */
}

/* Estilo quando o checkbox está marcado */
.checkbox-input:checked {

    width: 22px;
    height: 22px;
    
    transform: scale(1.1);
    background-image: url("../../../Assets/Icons/check_box_filled.png");
}

/* Estilo do ícone de check (pode ser um SVG ou um pseudo-elemento) */
.checkbox-input:checked::before {
    display: block;
    width: 14px;
    height: 14px;
    background-image: url("../../../Assets/Icons/check_box_filled.png");
    background-size: contain;
    background-repeat: no-repeat;
}