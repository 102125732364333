.return-button {
    display: flex;
    width: 3.375rem;
    height: 3.375rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1rem;
    border: 1px solid #919191;
    background: #1B1B1C;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.return-button-icon {
    width: 1.5rem;
    height: 1.5rem;
}